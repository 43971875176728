
.app-modal {
  //@include Modal();
  inset: 10% 20%;
}

.quiz-modal-overlay, .app-modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
}
