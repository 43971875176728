.panel {
    background-color: white;
    border-radius: 0rem;
    margin-bottom: 1rem;
    border: 1px solid rgb(211, 211, 211);
    box-shadow: 0px 0px 5px 5px rgb(0, 0, 0, 0.01);
    border-radius: 1rem;

    @media(max-width: 768px) {
        border-radius: 0rem;
        box-shadow:none;
        border:none;
    }

    &:last-of-type {
        margin-bottom: 0rem;
    }

    .panel-title {
        font-weight: bold;
        font-size: 1.1rem;
        margin-bottom: 2rem;
    }
}
