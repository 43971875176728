.article-card {
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgb(200, 200, 200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .details {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 786px) {
  .article-card {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(200, 200, 200);
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .details {
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        color: black;
      }

    }
  }
}
